.dividebuy-payment-description {
    .payment-title {
        @include fonts($InterFont, $high, 16px, 500, 24px, 0.15px);
    }
    .payment-note {
        @include fonts($InterFont, $high, 14px, 500, 20px, 0.1px);
    }
    .dividebuy-checkout-row.dividebuy-checkout-header {
        margin-top: 16px;
        @include flex-between-start;
        .dividebuy_instalment_data {
            width: calc(calc(100% / 4) - 8px);
            .interest-main-figure {
                margin-left: 0;
            }
            .data {
                display: block;
                text-align: left;
            }
        }
        .dividebuy_instalment_data-2 {
            @include flex-between-start;
            width: calc(100% - calc(100% / 4) - 8px);
            & > * {
                width: calc(calc(100% / 3) - 8px);
            }
        }
        .data {
            @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
        }
    }
    .dividebuy-checkout-row {
        @include flex-between-center;
        margin-bottom: 16px;
        .dividebuy_instalment_data {
            width: calc(calc(100% / 4) - 8px);
            .data {
                @include flex-start-center;
            }
        }
        .dividebuy_instalment_data-2 {
            @include flex-between-start;
            width: calc(100% - calc(100% / 4) - 8px);
            & > * {
                width: calc(calc(100% / 3) - 8px);
            }
        }
        .interest-main-figure {
            margin-left: 4px;
        }
        .data {
            @include fonts($InterFont, $low, 14px, 500, 20px, 0.25px);
        }
        &.dividebuy-active-row {
            .data {
                @include fonts($InterFont, $high, 14px, 500, 20px, 0.25px);
            }
        }
        .interest-input {
            @include flex-start-start;
            input[type="radio"] {
                background-image: url("../../../Assets/Images/radiobtn-default.svg");
                cursor: pointer;
                background-repeat: no-repeat;
                content: "";
                @include hw(24px);
                -webkit-appearance: initial;
                display: inline-block;
                margin: 0;
                &:hover {
                  background-image: url("../../../Assets/Images/radiobtn-hovered.svg");
                }
              }
              input[type="radio"]:checked {
                background-image: url("../../../Assets/Images/radiobtn-checked.svg");
                cursor: pointer;
                background-repeat: no-repeat;
                content: "";
                @include hw(24px);
                -webkit-appearance: initial;
                display: inline-block;
                margin: 0;
                &:hover {
                  background-image: url("../../../Assets/Images/radiobtn-checked.svg");
                }
              }
              input[type="radio"]:not([disabled]):focus {
                box-shadow: none;
              }
              input[type="radio"]:hover:not([disabled]):focus {
                box-shadow: none;
              }
        }
    }
    .security-reason {
        margin-top: 20px;
        margin-bottom: 20px;
        @include fonts($InterFont, $high, 14px, 500, 20px, 0.1px);
    }
    @include ultra-mobile {
        margin-top: 20px;
        .payment-title {
            margin-bottom: 8px;
            @include fonts($InterFont, $high, 14px, 500, 20px, 0.1px);
        }
        .payment-note {
            border-bottom: 1px solid $line;
            padding-bottom: 12px;
        }
        .dividebuy-checkout-row.dividebuy-checkout-header {
            .data {
                text-align: center;
                @include fonts($InterFont, $high, 11px, 500, 16px, 0.5px);
            }
            .dividebuy_instalment_data {
                .data {
                    text-align: center;
                }
            }
            .dividebuy_instalment_data {
                width: calc(calc(100% / 4) - 4px);
            }
            .dividebuy_instalment_data-2 {
                @include flex-between-start;
                width: calc(100% - calc(100% / 4) - 4px);
                & > * {
                    width: calc(calc(100% / 3) - 4px);
                }
            }
        }
        .dividebuy-checkout-row {
            margin-bottom: 12px;
            .interest-input {
                input[type="radio"], input[type="radio"]:checked {
                    @include hw(20px);
                    background-size: 20px;
                }
            }
            .data {
                @include fonts($InterFont, $low, 12px, 400, 16px, 0.4px);
                text-align: center;
            }
            &.dividebuy-active-row {
                .data {
                    @include fonts($InterFont, $high, 12px, 400, 16px, 0.4px);
                }
            }
            .interest-main-figure {
                width: calc(100% - 40px);
                text-align: center;
            }
        }
        .security-reason {
            border-top: 1px solid $line;
            padding-top: 12px;
        }
    }
}
.install_popup {
    .modal-body {
        .dividebuyTable {
            display: table;
            width: 100%;
            margin-bottom: 24px;
            .dividebuyTableBody {
                display: table-row-group;
                .dividebuyTableRow {
                    display: table-row;
                    &.dividebuyTableRowhead {
                        .dividebuyTableCell {
                            padding: 0 8px 12px 8px;
                        }
                    }
                    .dividebuyTableCell {
                        display: table-cell;
                        text-align: center;
                        padding: 4px 8px;
                        @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
                        &:last-child {
                            @include fonts($InterFont, $high, 14px, 500, 20px, 0.1px);
                        }
                    }
                }
            }
        }
    }
}