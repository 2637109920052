/*Order Confirmation CSS Satrts*/
.ordrsucs-wrap {
	padding: 64px 0 96px 0;
	.custom-container {
		@include ultra-mobile {
			padding: 0 20px;
		}
		.ordrsucs-content {
			text-align: center;
			h1 {
				padding-bottom: 14px;
				display: block;
				@include fonts($InterFont, $high, 16px, 500, 24px, 0.15px);
			}
			img {
				height: 96px;
				width: 96px;
				margin: 0 auto 38px;
				@include ultra-mobile {
					height: 80px;
					width: 80px;
					margin: 0 auto 30px;
				}
			}
			p {
				&.lm {
					max-width: 200px;
					margin: auto;
					padding-bottom: 27px;
					border-bottom: 1px solid $line;
					margin-bottom: 25px;
				}
				&.bm {
					color: $black;
					padding-bottom: 12px;
					@include ultra-mobile {
						padding-bottom: 16px;
					}
					a {
						color: $black;
						border-bottom: 1px solid transparent;
						padding-bottom: 2px;
						&:hover {
							border-bottom: 1px solid $black;
						}
					}
				}
			}
			.fill-btn {
				width: 352px;
				margin-top: 15px;
				@include ultra-mobile {
					width: 100%;
				}
			}
		}
	}
}
/*Order Confirmation CSS End*/
/*checkout page start*/
.checkout {
	padding: 64px 0;
	@include ultra-mobile {
		padding: 0;
	}
	.res-checkout-header {
		@include ultra-mobile-above {
			display: none;
		}
		.ll {
			color: $high;
		}
		.checkout-header {
			@include flex-between-center;
			padding: 16px 20px 16px 20px;
			margin: 0 -20px;
			border-bottom: 1px solid $line;
			.shipping-cart {
				@include flex-between-center;
				img {
					margin-right: 16px;
					height: 24px;
					width: 24px;
				}
			}
			.step-txt {
				p {
					color: $low;
				}
			}
		}
		.email-box {
			margin: 0 -20px;
			.email-input {
				border-bottom: 1px solid $line;
				padding: 16px 20px 23px;
				.input-groups {
					@include ultra-mobile-above {
						margin-bottom: 0;
					}
				}
				.forgotpass {
					text-align: right;
					a {
						border-bottom: 1px solid $high;
						&:hover {
							border-color: transparent;
						}
					}
				}
				.fill-btn {
					&[disabled] {
						padding: 0 32px;
					}
				}
			}
			.address-btn {
				border-bottom: 1px solid $line;
				padding: 16px 20px;
				.outline-btn {
					width: 100%;
				}
			}
		}
	}
	.checkout-sticky-bar {
		position: fixed;
		width: 100%;
		bottom: 0;
		z-index: 2;
		@include prefix(box-shadow, 0px 2px 16px 4px rgba(40, 44, 63, 0.07));
		background-color: $white;
		.act-bar {
			padding: 8px 12px;
			@include flex-between-center;
			.fill-btn {
				padding: 0 40px;
			}
			span {
				color: $mid;
			}
		}
	}
	.custom-container {
		max-width: 1142px;
		margin: 0 auto;
		padding: 0 23px;
		@include desktop {
			padding: 0 23px;
		}
		@include ultra-mobile {
			padding: 0 20px;
		}
		.checkout-main {
			@include flex-between-start;
			@include tablet {
				@include prefix(flex-wrap, wrap);
			}
			.checkout-left {
				width: 100%;
				padding-right: 20px;
				max-width: 700px;
				@include tablet {
					width: 100%;
					padding-right: 0;
					max-width: 100%;
				}
				.ck-head {
					padding-bottom: 16px;
					@include ultra-mobile {
						display: none;
					}
					h1 {
						@include fonts($MarcellusFont, $high, 24px, 400, 32px, 0.03em);
					}
				}
				.ckout-inner {
					box-shadow: 0px 2px 16px 4px rgba(40, 44, 63, 0.07);
					-webkit-box-shadow: 0px 2px 16px 4px rgba(40, 44, 63, 0.07);
					-moz-box-shadow: 0px 2px 16px 4px rgba(40, 44, 63, 0.07);
					margin-bottom: 16px;
					@include ultra-mobile {
						box-shadow: unset;
					}
					.ckout-top-box {
						@include flex-between-center;
						padding: 18px 16px 17px 18px;
						@include ultra-mobile {
							padding: 20px 0 2px;
						}
						.ckout-head {
							@include flex-between-center;
							span {
								@include flex-center-center;
								height: 20px;
								width: 20px;
								line-height: 20px;
								background: $high;
								border-radius: 50%;
								margin-right: 10px;
								p {
									color: $white;
								}
								@include ultra-mobile {
									display: none;
								}
							}
						}
						@include ultra-mobile {
							a {
								display: none;
							}
						}
					}
					.ckout-content {
						border-top: 1px solid $line;
						@include ultra-mobile {
							border-top: unset;
							margin: 0 -20px;
						}
						.ckout-mid-box {
							@include flex-between-start;
							padding: 18px 16px 18px 18px;
							@include ultra-mobile {
								padding: 18px 20px 18px 19px;
								@include prefix(flex-wrap, wrap);
							}
							.ckout-address {
								@include flex-between-start;
								@include ultra-mobile {
									@include flex-start-start;
									width: 100%;
								}
								.rd {
									margin-right: 8px;
								}
								.ckout-txt {
									p {
										&.bm {
											padding-bottom: 4px;
										}
									}
									.fill-btn {
										margin: 15px 0 6px;
										display: block;
										@include ultra-mobile {
											display: none;
										}
									}
									a {
										border-bottom: unset;
										@include ultra-mobile {
											padding: 4px 0 12px;
										}
									}
								}
							}
							.ckout-edit {
								@include ultra-mobile {
									padding-left: 33px;
								}
								a {
									margin-left: 16px;
									@include desktop {
										margin-left: 10px;
									}
									@include ultra-mobile {
										margin: 0 16px 0 0;
									}
								}
							}
							&.bg-surface {
								background: $surface;
								.ckout-edit {
									padding-bottom: 16px;
								}
							}
						}
					}
					.summery-box {
						.ckout-summery {
							@include flex-start-start;
							padding: 16px 16px 19px 48px;
							border-top: 1px solid $line;
							.summery-img {
								margin-right: 16px;
								img {
									max-width: 160px;
								}
							}
							.summery-txt {
								ul {
									@include flex-start-center;
									padding: 7px 0 12px;
									li {
										padding: 0 7px 0 10px;
										position: relative;
										color: $mid;
										&:before {
											content: "";
											position: absolute;
											height: 4px;
											width: 4px;
											background: $line;
											border-radius: 50%;
											margin: 0 4px;
											left: 0;
											top: 0;
											bottom: 0;
											margin: auto;
										}
										&:first-child {
											padding-left: 0;
											&:before {
												display: none;
											}
										}
									}
								}
								.quantity {
									@include flex-start-center;
									padding-bottom: 2px;
									.qty-txt {
										margin-right: 8px;
										p {
											color: $mid;
										}
									}
								}
								.summery-price {
									@include flex-start-center;
									padding-bottom: 12px;
									p {
										&:last-child {
											text-decoration: line-through;
											padding-left: 8px;
											color: $mid;
										}
									}
								}
								.remove {
									color: $dark-low;
									padding-top: 12px;
									border-bottom: 1px solid $dark-low;
									&:hover {
										border-bottom: 1px solid transparent;
									}
								}
							}
						}
						.confirm-order {
							@include flex-between-center;
							padding: 9px 24px 24px 48px;
							p.bm {
								padding-right: 12px;
							}
							a {
								border: unset;
								color: $high;
							}
						}
					}
					.payment-box {
						padding: 18px 18px 6px 18px;
						border-top: 1px solid $line;
						@include ultra-mobile {
							padding: 18px 0px 0px 2px;
							border-top: unset;
						}
						.delivery-method {
							margin-bottom: 16px;
							.payment-action-content {
								margin-top: 24px;
								margin-left: 30px;
								@include tablet {
									margin-top: 12px;
								}
							}
							.credit-card-content {
								@include flex-between-center;
								margin-top: 24px;
								margin-left: 30px;
								@include prefix(flex-wrap, wrap);
								.cno {
									flex-basis: 100%;
								}
								.expidate,
								.cvv {
									flex-basis: calc(calc(100% / 2) - 8px);
								}
							}
							.list-addresses {
								margin-top: 24px;
								margin-left: 30px;
								@include tablet {
									margin-top: 12px;
								}
								select {
									max-width: 75%;
									padding-right: 40px;
								}
								.ckout-txt {
									p {
										&.bm {
											padding-bottom: 4px;
										}
									}
									.fill-btn {
										margin: 15px 0 6px;
										display: block;
										@include ultra-mobile {
											display: none;
										}
									}
									a {
										border-bottom: unset;
										@include ultra-mobile {
											padding: 4px 0 12px;
										}
									}
								}
							}
						}
						.payment-option {
							margin-bottom: 20px;
							.bm {
								padding-left: 10px;
							}
						}
					}
					.delivery-box {
						padding: 18px 18px 26px 18px;
						border-top: 1px solid $line;
						@include ultra-mobile {
							padding: 16px 0px 0px 2px;
							border-top: unset;
						}
						.delivery-method {
							@include flex-start-center;
							&:not(:first-child) {
								margin-top: 16px;
							}
							span {
								padding-left: 58px;
								@include ultra-mobile {
									padding-left: 27px;
								}
							}
							.rd {
								// min-width: 48px;
							}
						}
						.fill-btn {
							margin-top: 24px;
							margin-left: 30px;
						}
					}
					.with-border {
						border-bottom: 1px solid $high;
						&:hover {
							border-bottom: 1px solid transparent;
						}
					}
					.bm {
						color: $high;
					}
				}
			}
			.checkout-price {
				.total-table {
					// padding-top: 36px;
					padding-bottom: 14px;
					border-bottom: 1px solid $line;
					.sub-col {
						@include flex-between-start;
						.stitle {
							width: 100%;
							max-width: 264px;
							@include desktop {
								width: auto;
							}
						}
						.svalue {
							width: calc(100% - 264px);
							text-align: right;
							@include desktop {
								width: auto;
							}
						}
						&:not(:last-child) {
							padding-bottom: 12px;
						}
					}
				}
				.discount-box {
					margin: 24px 0;
					.extra-ip-groups {
						position: relative;
						input {
							display: block;
							border: 1px solid $line;
							box-sizing: border-box;
							height: 48px;
							width: 100%;
							padding: 0 16px;
							background: $white;
							@include prefix(box-shadow, none);
							@include prefix(border-radius, 4px);
							@include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
						}
						.dis-btn {
							position: absolute;
							right: 16px;
							top: 50%;
							transform: translateY(-50%);
							border: 0;
							background-color: transparent;
							@include hw(16px);
							padding: 0;
						}
					}
				}
				.order-total {
					padding-top: 14px;
					border-top: 1px solid $line;
					.maintotal {
						@include flex-between-start;
						.stitle {
							width: 100%;
							max-width: 264px;
							@include desktop {
								width: auto;
							}
						}
						.svalue {
							width: calc(100% - 264px);
							text-align: right;
							@include desktop {
								width: auto;
							}
						}
					}
				}
			}
			.checkout-price {
				max-width: 352px;
				padding-left: 0;
				width: 100%;
				@include tablet {
					padding-left: 0;
					max-width: 100%;
					width: 100%;
				}
				span {
					display: block;
					padding-bottom: 35px;
				}
				// .checkout-price-table {
				// 	width: 100%;
				// 	tbody {
				// 		tr {
				// 			.bm {
				// 				padding-bottom: 12px;
				// 			}
				// 			.detail-txt {
				// 				padding-right: 24px;
				// 			}
				// 			.price-txt {
				// 				text-align: right;
				// 			}
				// 		}
				// 	}
				// }
				// .discount-box {
				// 	border-top: 1px solid $line;
				// 	border-bottom: 1px solid $line;
				// 	padding: 24px 0 23px;
				// 	position: relative;
				// 	margin-bottom: 14px;
				// 	.input-groups {
				// 		margin-bottom: 0;
				// 		label {
				// 			display: none;
				// 		}
				// 	}
				// 	.outline-btn {
				// 		position: absolute;
				// 		border-left: 0;
				// 		right: 0;
				// 		padding: 0 12px 0;
				// 		top: 24px;
				// 		.bm {
				// 			padding-bottom: 0;
				// 			img {
				// 				height: 16px;
				// 				width: 16px;
				// 			}
				// 		}
				// 		&:hover {
				// 			border-color: $line;
				// 		}
				// 	}
				// }
			}
		}
	}
}
.address-popup {
	&.checkout-address {
		@include ultra-mobile {
			padding-left: 0;
		}
		.modal-dialog {
			.modal-body {
				.full-street {
					width: 100%;
					.input-groups {
						width: calc(100%);
					}
				}
				.postcode-wrap {
					@include flex-start-center;
					width: calc(50% - 8px);
					@include ultra-mobile {
						width: 100%;
					}
					.input-groups {
						display: inline-block;
						width: calc(100% - 168px);
						input {
							border-right: 0;
							border-top-right-radius: 0;
							border-bottom-right-radius: 0;
						}
					}
					.fill-btn {
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
						width: 168px;
						padding: 0 23px;
					}
				}
			}
		}
	}
}
/*checkout page end*/
/*checkout page skeleton start*/
.checkout.skl {
	overflow: hidden;
	.res-checkout-header.skl {
		.checkout-header {
			.shipping-cart {
				.arrow-img {
					height: 24px;
					width: 24px;
					margin-right: 16px;
				}
				.shpng-txt {
					height: 20px;
					width: 150px;
				}
			}
			.step-txt {
				.step {
					height: 20px;
					width: 70px;
				}
			}
		}
		.email-box {
			.email-input {
				.label {
					max-width: 30%;
					margin-bottom: 8px;
				}
				.input-box {
					height: 48px;
					span {
						height: 100%;
					}
				}
			}
			.address-btn {
				.addbtn {
					height: 48px;
					span {
						height: 100%;
					}
				}
			}
		}
	}
	.checkout-main.skl {
		.checkout-left {
			.ck-head {
				h6 {
					max-width: 30%;
				}
			}
			.ckout-inner {
				.ckout-top-box {
					.skl-head {
						@include flex-between-center;
						.numberskl {
							width: 20px;
							margin-right: 10px;
							@include ultra-mobile {
								display: none;
							}
							span {
								min-height: inherit;
								border-radius: 50%;
							}
						}
						.titleskl {
							min-width: 300px;
							@include ultra-mobile {
								min-width: 250px;
							}
							span {
								min-height: inherit;
							}
						}
					}
					.address-skl {
						min-width: 20%;
						@include ultra-mobile {
							display: none;
						}
						span {
							min-height: inherit;
							height: inherit;
						}
					}
				}
				.ckout-content {
					.ckout-mid-box {
						.ckout-address {
							@include ultra-mobile {
								width: 100%;
							}
							.rd-skl {
								width: 20px;
								margin-right: 10px;
								span {
									min-height: inherit;
									border-radius: 50%;
								}
							}
							.ckout-skl {
								min-width: 400px;
								margin-right: 10px;
								@include ultra-mobile {
									min-width: 92%;
									margin: 0 0 12px;
								}
								@include mini-iphone-mobile {
									min-width: 90%;
								}
								span {
									min-height: 70px;
								}
							}
							.deliver-btn {
								margin: 15px 0 6px;
								span {
									min-height: 48px;
									width: 165px;
								}
							}
						}
						.ckout-edit {
							width: 55%;
							margin-left: 34px;
							display: flex;
							@include ultra-mobile {
								margin-left: 0;
							}
							p {
								width: 55%;
								margin-left: 15px;
								@include ultra-mobile {
									margin-left: 0;
									&:not(:last-child) {
										margin-left: 0;
										margin-right: 15px;
									}
								}
							}
						}
					}
				}
				.summery-box.skl {
					.ckout-summery {
						@include ultra-mobile {
							border: unset;
							@include prefix(flex-wrap, wrap);
							padding: 16px 0px 19px 0px;
						}
						.summery-img {
							width: 160px;
							@include ultra-mobile {
								width: 100%;
								margin-right: 0;
								margin-bottom: 10px;
							}
							span {
								min-height: 120px;
								@include ultra-mobile {
									min-height: 100px;
									width: 100px;
								}
							}
						}
						.summery-txt {
							.prod-name {
								width: 150px;
							}
							.prod-det {
								width: 350px;
								padding: 7px 0 12px;
								@include ultra-mobile {
									width: 300px;
								}
							}
							.qty {
								width: 250px;
								margin-bottom: 10px;
								span {
									height: 30px;
								}
							}
							.prod-price {
								width: 100px;
							}
							.order-remove {
								margin-top: 12px;
								width: 70px;
							}
						}
					}
					.confirm-order {
						@include ultra-mobile {
							@include prefix(flex-wrap, wrap);
							padding: 9px 0 24px 0;
						}
						.order-email {
							height: 20px;
							width: 438px;
						}
						.continew-btn {
							margin-left: 36px;
							width: 192px;
							@include ultra-mobile {
								margin-left: 0;
								margin-top: 10px;
							}
							span {
								height: 48px;
							}
						}
					}
				}
				.delivery-box.skl {
					.delivery-method {
						span {
							padding-left: 0;
						}
						.rd-skl {
							width: 20px;
							margin-right: 10px;
							span {
								min-height: inherit;
								border-radius: 50%;
							}
						}
						.price {
							width: 20px;
							margin-right: 58px;
						}
						.credit {
							width: 150px;
						}
						.paypal {
							width: 100px;
						}
						.delivery-txt {
							width: 200px;
						}
					}
					.continew-btn {
						width: 192px;
						margin-left: 30px;
						margin-top: 24px;
						@include ultra-mobile {
							display: none;
						}
						span {
							height: 48px;
						}
					}
				}
			}
		}
		.checkout-price.skl {
			@include tablet {
				margin-top: 10px;
			}
			span {
				padding-bottom: 0;
				height: 20px;
				@include ultra-mobile {
					height: 10px;
				}
			}
			.detail {
				max-width: 150px;
				span {
					padding-bottom: 0;
					margin-bottom: 35px;
					height: 25px;
				}
			}
			.total-table.skl {
				.total {
					.stitle {
						width: 144px;
					}
				}
				.discount {
					.stitle {
						width: 204px;
						@include desktop {
							width: 180px;
						}
					}
				}
				.delivery {
					.stitle {
						width: 244px;
						@include desktop {
							width: 200px;
						}
					}
					.svalue {
						width: 50px;
					}
				}
				.svalue {
					@include media-1299-below {
						width: calc(100% - 204px);
					}
					@include tablet {
						width: 120px;
					}
				}
			}
			.discount-box {
				@include ultra-mobile {
					margin: 12px 0px;
				}
				.coupon {
					span {
						height: 48px;
						@include ultra-mobile {
							height: 28px;
						}
					}
				}
			}
			.order-total {
				.stitle {
					width: 120px;
				}
				.svalue {
					@include media-1299-below {
						width: calc(100% - 204px);
					}
					@include tablet {
						width: 120px;
					}
				}
			}
		}
	}
	.checkout-sticky-bar.skl {
		.act-bar {
			.od-total {
				.od-total-txt {
					min-height: 10px;
					width: 100px;
					margin-bottom: 5px;
				}
			}
			.od-action {
				span {
					width: 132px;
					height: 48px;
				}
			}
		}
	}
}
/*checkout page skeleton end*/
